import React from "react"
import Seo from "../components/seo"

const privacy = () => {

return (
    <>
    <Seo title="Условия обработки персональных данных" 
    description="Условия обработки персональных данных"
    noindex={true} />
    <div className="container">
    <article>
        <header>
            <h1>Условия обработки персональных данных</h1>
        </header>
        <div>
        <p>Данная политика конфиденциальности разработана в соответствии с Федеральным законом Российской Федерации от 
        27.07.2006 г. № 152-ФЗ «О персональных данных». Все лица заполнившие сведения, составляющие персональные данные, 
        на сайте SVISNI-SUSHI.RU, а также разместившие иную информацию, обозначенными действиями подтверждают свое
        согласие, данное ИП БЕЖЕНОВА ТАТЬЯНА ВИКТОРОВНА, ИНН 312608518612, ОГРНИП 318312300012678 от 08.06 .2018 г.,
        адрес: 603024, п. Уразово, ул. 3-го Интернационала, д.48А, далее "СВИСНИ СУШИ", на обработку персональных 
        данных и их передачу оператору обработки персональных данных.</p>

        <p>Под персональными данными Гражданина понимается указанная общая информация: Имя, Телефон, E-mail. 
        Пользователи, принимая настоящее Соглашение, выражают свою заинтересованность и полное согласие, что обработка 
        их персональных данных может включать в себя следующие действия: сбор, систематизацию, накопление, хранение, 
        уточнение (обновление, изменение), использование, уничтожение.</p>

        <p>Пользователь гарантирует: информация, им предоставленная, является полной, точной и достоверной; 
        при предоставлении информации не нарушается действующее законодательство Российской Федерации, законные права 
        и интересы третьих лиц; вся предоставленная информация заполнена Пользователем в отношении себя лично.</p>

        <p> СВИСНИ СУШИ обеспечивает соблюдение конфиденциальности в отношении каждого посещения сайта SVISNI-SUSHI.RU.
        Политика обеспечения конфиденциальности, проводимая СВИСНИ СУШИ, заключается в идентификации 
        собранной информации и применении правил использования таковой и сводится к следующему: </p>

        <p>СВИСНИ СУШИ осуществляет сбор частной информации (Ф.И.О., адрес, номер телефона/факса и адреса электронной 
        почты) только в том случае, когда такая информация лично предоставляется посетителем сайта.</p>

        <p>СВИСНИ СУШИ не продает, не передает в аренду и не предоставляет каким-либо другим образом в распоряжение 
        третьих сторон частную информацию, предоставленную посетителями сайта SVISNI-SUSHI.RU.</p>

        <p> Указанная информация не будет использована в целях прямого маркетинга или в рамках последующих мероприятий, проводимых по собственной инициативе компании, если владелец такой информации не будет заранее проинформирован о возможности такого использования и не даст при предоставлении таковой особого разрешения 
        на ее использование. </p>

        <p>
        СВИСНИ СУШИ может использовать данную информацию для следующих целей:
            <ul>
                <li><p>Отправка на электронный адрес пользователя пресс-релизов или уведомлений о совершаемых им заказах</p></li>
                <li><p>Информирование пользователя о товарах, услугах и проводимых мотивационных программах</p></li>
            </ul>
        </p>

        <p>Только уполномоченный персонал СВИСНИ СУШИ или агенты компании (которые приняли обязательство о неразглашении 
        информации) имеют доступ к частной информации посетителей.</p>

        <p>СВИСНИ СУШИ использует статистическую информацию, не являющуюся частной информацией, полученную от 
        пользователей (тип браузера, территориальное положение, возраст, пол), для оптимизации сайта как часть 
        непрерывного внутреннего процесса анализа и в целом для более глубокого изучения клиентов и их потребностей.</p>

        <p>СВИСНИ СУШИ не будет предоставлять такую информацию третьим лицам. Указанная информация будет 
        использована исключительно в заявленных целях и не будет использована для каких-либо проводимых по 
        собственной инициативе информационных мероприятиях без получения на то согласия владельца такой информации.</p>
        
        <p>Согласие на обработку персональных данных дается бессрочно и может быть отозвано Пользователем в любое время, 
        путем направления письменного обращения о прекращении использования персональных данных или отписки от рекламных 
        и новостных рассылок.</p>
        
        <p>Дата публикации: <b>10 февряля 2020 г.</b></p>

        <p>Уведомления, в случае внесения изменений, размещаются на сайте svisni-sushi.ru в виде информационного сообщения.</p>
        </div>
    </article>
    </div>
    </>
    
    )
};

export default privacy
